import React from "react"
import "./footer.scss"

export default () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-pat" />
        <div className="footer-content">
          <span>wwww.nosotrasonline.com.co</span>
          <div className="footer-icons">
            <a
              href="https://www.facebook.com/NosotrasColombia/"
              target="_blank"
              rel="noopener noreferrer"
              className="fb-icon"
            ></a>
            <a
              href="https://www.instagram.com/nosotras/"
              target="_blank"
              rel="noopener noreferrer"
              className="ig-icon"
            ></a>
            <a
              href="https://twitter.com/YoAmoNosotras"
              target="_blank"
              rel="noopener noreferrer"
              className="tw-icon"
            ></a>
            <a
              href="https://www.youtube.com/user/videosNosotras"
              target="_blank"
              rel="noopener noreferrer"
              className="yt-icon"
            ></a>
          </div>
        </div>
      </div>
    </footer>
  )
}
